<template>
  <div class="cat-img" :class="{'p': !open, 'op': open, 'bot':bot}">
    <transition name="pop-out" mode="out-in">
      <div :key="displayCount" :class="`counter rot-${counterRotation}`">{{ displayCount.toLocaleString('en') }}</div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ['open', 'counter', 'bot'],

  computed: {
    displayCount () {
      return this.counter > 0 ? this.counter : ''
    }
  },

  data () {
    return {
      counterRotation: 'c'
    }
  },

  watch: {
    counter: function () {
      const rotations = ['l', 'r', 'c', 'll', 'rr']
      this.counterRotation = rotations[Math.floor(Math.random() * rotations.length)]
    }
  },

  mounted () {
    // preload open image
    const preload = new Image()
    preload.src = require('@/assets/op.png')
  }
}
</script>

<style scoped>
.cat-img {
    height:0;
    flex-grow:1;
    width:100%;
    max-width: 800px;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    touch-action: manipulation;
    text-align: center;
    color:white;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
    font-weight:900;
    font-size:80px;
    word-wrap: break-word;
}
@media(min-width:1000px) {
    .cat-img {
        font-size: 40px;
        -webkit-text-stroke-width: 1.5px;
    }
}
.p { background-image: url("~@/assets/p.png"); }
.op { background-image: url("~@/assets/op.png"); }
.p.bot { background-image: url("~@/assets/p-bot.png"); }
.op.bot { background-image: url("~@/assets/op-bot.png"); }

.pop-out-leave {
  display: none;
}

.pop-out-enter-active {
  transition: transform 80ms ease-in-out;
}

.pop-out-enter.rot-ll{
  transform: scale(1.2) rotate(-10deg);
}

.pop-out-enter.rot-l{
  transform: scale(1.2) rotate(-5deg);
}

.pop-out-enter.rot-c{
  transform: scale(1.3) rotate(0deg);
}

.pop-out-enter.rot-r{
  transform: scale(1.2) rotate(5deg);
}

.pop-out-enter.rot-rr{
  transform: scale(1.2) rotate(10deg);
}

.pop-out-enter-to {
  transform: scale(1);
}
</style>
