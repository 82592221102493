<template>
  <span class="animated-number">
    <transition v-if="pop" name="number-punch" mode="out-in">
      <span class="anim" :key="tweeningValue.toLocaleString('en')">
        {{ tweeningValue.toLocaleString('en') }}
      </span>
    </transition>
    <span v-else>{{ tweeningValue.toLocaleString('en') }}</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    duration: {
      type: Number,
      required: false,
      default: 5000
    },
    enabled: {
      type: Boolean,
      default: true,
      required: false
    },
    pop: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      tweeningValue: this.value
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (this.enabled && oldValue < newValue && oldValue !== 0) {
        this.tween(oldValue, newValue)
      } else {
        this.tweeningValue = newValue
      }
    }
  },
  methods: {
    tween: function (startValue, endValue) {
      const vm = this
      function animate () {
        if (endValue !== vm.value) return
        const complete = (Date.now() - startTime) / vm.duration
        if (complete >= 1 || !vm.enabled) {
          vm.tweeningValue = endValue
        } else {
          const newValue = Math.round(startValue + ((endValue - startValue) * complete))
          if (newValue !== vm.tweeningValue) {
            vm.tweeningValue = newValue
          }
          requestAnimationFrame(animate)
        }
      }
      const startTime = Date.now()
      animate()
    }
  }
}
</script>

<style scoped>

.number-punch-enter-active {
  transition: transform 50ms ease-in-out;
}

.number-punch-enter {
  transform: scale(1.1);
}

.number-punch-enter-to {
  transform: scale(1);
}

.animated-number {
  position: relative;
}

.anim {
  display: inline-block;
}
</style>
