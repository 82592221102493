import axios from 'axios'
import countriesMap from '@/assets/data/countries.json'

function load (endpoint) {
  return new Promise((resolve, reject) => {
    axios.get(endpoint)
      .then((resp) => {
        if (resp.status !== 200) {
          reject(new Error(resp.status))
        }

        // determine if this is the new leaderboard type.
        const data = resp.data

        if (data.Countries) {
          // Old format endpoint - no need to add country names.
          resolve(data.Countries)
        } else if (data.GB || data.IE || data.US || data.JP) {
          // New format endpoint - load country names from .json.
          const countriesList = []

          for (const [Code, Pops] of Object.entries(data)) {
            const Name = countriesMap[Code]
            if (!Name) continue

            countriesList.push({
              Name,
              Code,
              Pops
            })
          }
          resolve(countriesList)
        } else {
          reject(new Error('Leaderboard API response does not match expected format.'))
        }
      })
      .catch((e) => {
        // if the data failed to load, don't do anything
        // if it's the first fetch, the board won't appear
        // otherwise it'll just keep the stale data
        reject(e)
      })
  })
}

export default {
  load
}
