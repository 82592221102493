<template>
  <figure class="ad-wrapper" :class="`ad-wrapper-${type}`" ref="ad-wrapper">
    <div class="ad-container" :id="adID">
      <p v-if="placeholder == 0">Check out <a href="https://maxwellcat.world/">maxwellcat.world</a>!</p>
      <p v-else-if="placeholder == 1">Have you tried <a href="https://spinningrat.online/">spinningrat.online</a>?</p>
      <p v-else>Buy official <a href="https://shop.popcat.click/">popcat.click merch</a>.</p>
    </div>
  </figure>
</template>

<script>
import { adSlotIds } from '../lib/ads'

export default {
  props: {
    type: String
  },

  data() {
    return {
      placeholder: Math.floor(Math.random() * 3)
    }
  },

  computed: {
    adID () {
      return adSlotIds[this.type] || null
    }
  }
}
</script>

<style scoped>
figure {
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid #6c757d;
  border-radius: 0.75rem;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

figure::before {
  content: "Ad";
  display: inline-block;
  position: absolute;
  top: -0.5rem;
  left: 1rem;
  background: white;
  padding: 0 0.25rem;
  font-size: 0.85rem;
  text-align: center;
  line-height: 1rem;
  height: 1rem;
}

p {
  position: absolute;
}

.ad-wrapper-desktop {
  /* Set the min height to avoid ad area reducing when empty */
  min-height: calc(90px + 1rem);
}

.ad-wrapper-mobile {
  /* Set the min height to avoid ad area reducing when empty */
  min-height: calc(50px + 1rem);
  /* Widen and remove side borders to take up leaderboard width */
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: 0;
  border-right: none;
  border-left: none;
  border-radius: 0;
  border-bottom: none;
}

.ad-container {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

@media screen and (max-width: 50rem) {
  #nn_mobile_lb1 {
    width: 100%;
    max-width: 320px;
    height: 50px;
  }
  .ad-wrapper-desktop {
    display: none;
  }
}

@media screen and (min-width: 50rem) {
  .ad-wrapper-mobile {
    display: none;
  }
  #nn_lb1 {
    width: 100%;
    max-width: 728px;
    height: 90px;
  }
}

</style>
