import Vue from 'vue'
import App from './App.vue'

Vue.use(require('vue-script2'))

Vue.config.productionTip = false

new Vue({
  render: h => h(App)
}).$mount('#app')

console.log(`Popping on over to ${process.env.VUE_APP_ENDPOINT_POP}.`)
console.log('%cWARNING', 'font-size:10em;color:red;')
console.log(`%cThis is a browser feature intended for developers.
Do NOT copy and paste something here if you do not understand it.

You can learn more at:
https://en.wikipedia.org/wiki/Self-XSS`,
'font-size:2em')
