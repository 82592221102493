<template>
  <div v-if="code != 'NP'" :class="`flag flag:${code}`" :title="getLabel(code)" :aria-label="getLabel(code)"></div>
  <svg class="flag flag:NP" v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 16"><defs/><defs><filter id="a" width="173%" height="160%" x="-36.5%" y="-30%" filterUnits="objectBoundingBox"><feOffset in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1.6"/><feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.423529412 0 0 0 0 0.458823529 0 0 0 0 0.490196078 0 0 0 0.5 0"/></filter><path id="b" d="M0 16V0l13.14 8.19H4.11l8.6 7.81z"/></defs><g fill="none"><path d="M0 0h24v16H0z"/><use fill="#000" filter="url(#a)" href="#b"/><use fill="#0052B4" href="#b"/><path fill="#D80027" d="M11.47 7.71L.48.86v14.66h10.99l-8.6-7.81z"/><g fill="#FFF"><path d="M4.6 11.2l-.69-.31.36-.67-.74.15-.1-.75-.51.55-.52-.55-.1.75-.73-.15.36.67-.68.32.68.32-.36.66.74-.14.1.75.51-.55.52.55.1-.75.73.14-.36-.66.68-.32zm-.46-6.22l-.5-.23.26-.48-.54.1-.06-.54-.38.4-.38-.4-.06.54-.55-.1.27.48-.5.23 1.22.25 1.22-.25zM4.38 4.98a1.46 1.46 0 11-2.92 0"/></g></g></svg>
</template>

<script>
import countriesMap from '@/assets/data/countries.json'

export default {
  props: ['code'],

  methods: {
    getLabel (code) {
      const name = countriesMap[code] || code
      return `Flag of ${name}`
    }
  }
}
</script>

<style src="country-flag-icons/3x2/flags.css">
</style>

<style scoped>
.flag {
  border-radius: 0.2rem;
  box-shadow: 0rem 0rem 0.2rem #6c757d80;
  flex-shrink: 0;
  height: 1em;
  width: 1.5em;
}

.flag\:NP {
  border-radius: 0;
  box-shadow: none;
  overflow: visible;
}
</style>
