const adSlotIds = {
  desktop: 'nn_lb1',
  mobile: 'nn_mobile_lb1'
}

function adLog (message) {
  if (process.env.VUE_APP_DEBUG_ADS === 'true') {
    console.info(message)
  }
}

function anyContainersVisible () {
  return document.getElementsByClassName('ad-wrapper').length > 0
}

function areAnyAdsVisible () {
  return Object.values(adSlotIds).some((adId) => {
    const adElem = document.getElementById(adId)

    if (!adElem) {
      adLog(adElem + ' not found on page')
      return false
    }

    const isAdVisible = adElem.hasChildNodes() && adElem.style.display !== 'none'

    if (isAdVisible) {
      adLog(adId + ' has an advert and is visible')
    } else {
      adLog(adId + ' does not have an advert or is not visible')
    }

    return isAdVisible
  })
}

function adScriptsAvailable () {
  return typeof window.reloadAdSlots !== 'undefined'
}

const adManagerMixin = {
  data () {
    return {
      lastAdsReload: null,
      refreshInterval: null
    }
  },

  mounted () {
    this.refreshInterval = setInterval(this.reloadAdSlots, 5000)
    this.reloadAdSlots()
  },

  beforeDestroy () {
    clearInterval(this.refreshInterval)
  },

  methods: {
    reloadAdSlots () {
      adLog('Attempting to reload ad slots')

      if (!anyContainersVisible()) {
        adLog('No containers are visible, stopping.')
        return
      }

      if (!adScriptsAvailable()) {
        adLog('Ad scripts aren\'t ready yet')
        return
      }

      adLog('Ads are visible and scripts are ready, trying to reload')

      adLog('Ads were last loaded at ' + this.lastAdsReload)

      // If it's been a minute since we last reloaded ads, or no ads are visible
      const adsNeedReloading = this.lastAdsReload !== null && (new Date() - this.lastAdsReload > 60 * 1000)

      if (adsNeedReloading || !areAnyAdsVisible()) {
        adLog('Ads are due for reloading, attempting...')

        this.lastAdsReload = new Date()
        window.reloadAdSlots()
      } else {
        adLog('Adverts were loaded too recently, stopping.')
      }
    }
  }
}

export { adManagerMixin, adSlotIds }
