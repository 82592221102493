<template>
  <div>
    <ol class="list" v-if="leaderboard">
      <li>
        <span class="position">🌍</span>
        <span class="country">Worldwide</span>
        <span class="count" translate="no">
          <transition name="pop-delta" mode="out-in">
            <span class="delta notranslate" translate="no" v-if="worldwide.pps > 0">{{shortNum(worldwide.pps) + " PPS"}}</span>
          </transition>
          <AnimatedNumber :enabled="open" :transition="false" :value="worldwide.pops" :duration="smoothedInterval"/>
        </span>
      </li>
      <li v-for="(country, i) in leaderboard.slice(0, adPosition)" :key="country.Code">
        <span class="position">{{getPlace(i + 1)}}</span>
        <flag :code="country.Code"/>
        <span :class="`country ${location === country.Code ? 'mine' : ''}`">{{ country.Name }}</span>
        <span class="count" translate="no">
          <transition name="pop-delta" mode="out-in">
            <span class="delta notranslate" translate="no" v-if="deltas[country.Code]">{{shortNum(deltas[country.Code]) + " PPS"}}</span>
          </transition>
          <AnimatedNumber :enabled="open" :transition="false" :value="country.Pops" :duration="smoothedInterval"/>
        </span>
      </li>
      <li v-for="(country, i) in leaderboard.slice(adPosition)" :key="country.Code">
        <span class="position">{{getPlace(i + 1 + adPosition)}}</span>
        <flag :code="country.Code"/>
        <span :class="`country ${location === country.Code ? 'mine' : ''}`">{{ country.Name }}</span>
        <span class="count" translate="no">
          <transition name="pop-delta" mode="out-in">
            <span class="delta notranslate" translate="no" v-if="deltas[country.Code]">{{shortNum(deltas[country.Code]) + " PPS"}}</span>
          </transition>
          <AnimatedNumber :enabled="open" :transition="false" :value="country.Pops" :duration="smoothedInterval"/>
        </span>
      </li>
    </ol>
    <p v-else>Loading...</p>
    <div class="leaderboard-key">PPS = Pops Per Second</div>
  </div>
</template>

<script>
import Flag from './Flag.vue'
import AnimatedNumber from './AnimatedNumber.vue'
import shortNumber from 'short-number'

export default {
  components: { Flag, AnimatedNumber },
  props: ['location', 'leaderboard', 'open'],

  data () {
    return {
      worldwide: {
        pops: 0,
        pps: 0
      },
      deltas: {},
      updated: Date.now(),
      smoothedInterval: 5000,
      adPosition: 3
    }
  },

  watch: {
    leaderboard (newBoard, oldBoard) {
      const secsSinceLastUpdate = (Date.now() - this.updated) / 1000

      // smooth the interval for animations to account for jitter in the connection
      const smoothedInterval = ((Date.now() - this.updated) + this.smoothedInterval) / 2
      this.smoothedInterval = Math.max(smoothedInterval + 30, 5000)

      const worldwidePops = this.leaderboard.reduce((prev, curr) => {
        return prev + curr.Pops
      }, 0)

      const worldwideDelta = worldwidePops - this.worldwide.pops
      this.worldwide.pops = worldwidePops

      this.updated = Date.now()

      // If there's no old board we can't compare so return immediately.
      if (!oldBoard) {
        this.worldwide.pps = 0
        return
      }

      this.worldwide.pps = Math.round((worldwideDelta / secsSinceLastUpdate) * 10) / 10

      // Clear deltas
      this.deltas = {}
      // Make lookup table of country code -> pops in old vals
      const popsByCountry = {}
      for (const country of oldBoard) {
        popsByCountry[country.Code] = country.Pops
      }
      // Compare oldvals to newvals. If new > old then add delta to deltas
      for (const country of newBoard) {
        if (!popsByCountry[country.Code]) { continue }
        if (popsByCountry[country.Code] < country.Pops) {
          const pps = (country.Pops - popsByCountry[country.Code]) / secsSinceLastUpdate
          this.deltas[country.Code] = Math.round(pps * 10) / 10
        }
      }
    }
  },

  methods: {
    getPlace (i) {
      switch (i) {
        case 1:
          return '🥇'
        case 2:
          return '🥈'
        case 3:
          return '🥉'
        default:
          return i
      }
    },

    shortNum (i) {
      return shortNumber(i)
    }
  }
}
</script>

<style>
@media(max-width:500px) {
  .desktop-ad { display:none !important; }
}
@media(min-width:501px) {
  .mobile-ad { display:none !important; }
}
</style>

<style scoped>
.ellipsis {
  margin: 0 1rem;
}

.leaderboard-key {
  padding-top: 1rem;
  text-align: center;
}

.open main {
  max-height: min(var(--max-height), 70vh);
}

ol {
  margin: 0;
  padding: 0;
  list-style: none;
  max-width: 50rem;
  margin: 0 auto;
}

.list li {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.list li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.list li:first-child {
  padding-top: 0;
}
@media(max-width:500px) {
  .list li:first-child {
    padding-top: 1rem;
  }
}

.list li > * {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.list .position {
  min-width: 1.375rem;
  text-align: center;
}

.list .country.mine {
  font-weight: bold;
}

.list .count {
  margin-left: auto;
}

.delta {
  color: #008000;
  font-weight: bold;
  font-size: 0.75rem;
  white-space: pre;
  padding-right: 0.25rem;
}

.pop-delta-leave, .pop-delta-enter-to {
  opacity: 1;
}
.pop-delta-leave-active, .pop-delta-enter-active {
  transition: opacity 400ms ease;
}
.pop-delta-leave-to, .pop-delta-enter {
  opacity:0;
}
</style>
