<template>
  <section :class="`leaderboard ${open ? 'open' : ''}`" @click.stop="" @pointerdown.stop="">
    <div class="bar" @click.stop="toggleLeaderboard">
      <h1>🏆</h1>
      <div class="preview">
        <div class="top-three" v-if="!open && leaderboard && (amInTopThree || !location)">
          <span
            :class="`top-entry ${currentCountry.Code === country.Code ? 'mine' : ''}`"
            v-for="(country, i) in topThree"
            :key="country.Code"
          >
            <span class="position">#{{i + 1}}</span>
            <flag :code="country.Code"/>
            <span class="count">
              {{shortNum( country.Code == currentCountry.Code ? currentCountry.Pops : country.Pops )}}
            </span>
          </span>
        </div>
        <div class="top-three" v-else-if="!open && leaderboard && !amInTopThree && location">
          <span class="top-entry">
            <span class="position">#1</span>
            <flag :code="topThree[0].Code"/>
            <span class="count">
              {{ shortNum(topThree[0].Pops) }}
            </span>
          </span>
          <span class="ellipsis">...</span>
          <span class="top-entry mine">
            <flag :code="currentCountry.Code"/>
            <span class="count">
              <AnimatedNumber :duration="20" :pop="true" :value="currentCountry.Pops"/>
            </span>
          </span>
        </div>
        <h2 v-else>Leaderboard</h2>
      </div>
      <button class="show">
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
        </svg>
      </button>
    </div>
    <main ref="mainGrow" :style="`--max-height: ${mainHeight}px`">
      <div class="main-wrap">
        <Leaderboard :accumulator="accumulator" :location="location" :leaderboard="leaderboard" :open="open" />
        <TwoTwelve/>
        <div class="mini">We use <a href="/privacy#cookies">Cookies</a>. Here's our <a href="/privacy">Privacy Notice</a>.</div>
      </div>
    </main>
    <StyledAd adslot="4885758928" type="desktop"/>
    <StyledAd adslot="6804203610" type="mobile"/>
  </section>
</template>

<script>
import Flag from './Flag.vue'
import StyledAd from './StyledAd.vue'
import Leaderboard from './Leaderboard.vue'
import AnimatedNumber from './AnimatedNumber.vue'
import leaderboardData from '@/assets/leaderboardData.js'
import shortNumber from 'short-number'
import { adManagerMixin } from '../lib/ads'
import TwoTwelve from './TwoTwelve.vue'

export default {
  components: { Leaderboard, Flag, AnimatedNumber, StyledAd, TwoTwelve },
  props: ['accumulator', 'location'],

  mixins: [adManagerMixin],

  data () {
    return {
      errored: false,
      leaderboard: null,
      open: false,
      worldwide: {
        pops: 0,
        pps: 0
      },
      mainHeight: 0,
      updated: Date.now(),
      smoothedInterval: 5000,
      currentCountry: {}
    }
  },

  watch: {
    accumulator (newVal, oldVal) {
      // The value in the accumulator has changed
      // if newVal < oldVal, ignore
      if (newVal < oldVal) {
        return
      }

      // if the new value is > the old value, subtract old and add new to pop count
      this.currentCountry.Pops = this.currentCountry.Pops - oldVal + newVal
    },
    location () {
      this.updateCurrentCountry()
    }
  },

  computed: {
    topThree () {
      return this.leaderboard.slice(0, 3)
    },

    amInTopThree () {
      let res = false
      this.topThree.forEach((v) => {
        if (v.Code === this.currentCountry.Code) {
          res = true
        }
      })
      return res
    }
  },

  created () {
    // fetch the leaderboard, it'll manage it's own request interval.
    this.fetchLeaderboardData()
  },

  mounted () {
    this.mainHeight = this.$refs.mainGrow.scrollHeight
  },

  methods: {
    fetchLeaderboardData () {
      leaderboardData.load(process.env.VUE_APP_ENDPOINT_LEADERBOARD)
        .then((res) => {
          this.leaderboard = res
          this.leaderboard.sort((a, b) => b.Pops - a.Pops)
          this.updateCurrentCountry()
          this.$nextTick(() => {
            // set the maximum height of the leaderboard, so the animation looks nice.
            this.mainHeight = this.$refs.mainGrow.scrollHeight
          })
        })
        .catch((e) => {
          // if the data failed to load, don't do anything
          // if it's the first fetch, the board won't appear
          // otherwise it'll just keep the stale data
          // console.log(e)
        })
        .finally(() => {
          // once the request has completed, schedule the next leaderboard update.
          setTimeout(this.fetchLeaderboardData, 5 * 1000)
        })
    },

    updateCurrentCountry () {
      // if the user's country hasn't been looked up by the server yet, or is unknown, don't return
      if (this.location === '' || this.location === '?' || !this.leaderboard) return

      const currentCountry = this.leaderboard.filter((country) => {
        return this.location === country.Code
      })[0]

      if (currentCountry) {
        this.currentCountry = {
          Code: this.location,
          Pops: currentCountry.Pops
        }
      }
    },

    toggleLeaderboard () {
      this.open = !this.open
      gtag('event', this.open ? 'leaderboard_opened' : 'leaderboard_closed')
      this.$nextTick(() => { this.$refs.mainGrow.style = '--max-height:' + this.$refs.mainGrow.scrollHeight + 'px' })
    },

    shortNum (i) {
      return shortNumber(i)
    }
  }
}
</script>

<style scoped>
.leaderboard {
  width: 100%;
  background: #fff;
  position: absolute;
  bottom: 0;
  max-width: 50rem;
  margin: 0 auto;
}

@media screen and (min-width: 50rem) {
  .leaderboard {
    border-radius: 1rem 1rem 0 0;
  }
}

.bar {
  display: flex;
  padding: 1rem 0;
  flex-direction: row;
  cursor: pointer;
}

.bar > * {
  padding: 0 1rem;
  display: block;
  border-right: 1px solid #eee;
  cursor: pointer;
}

.bar > *:last-child {
  border-right: none;
}

h1, h2 {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
}

.show {
  background: none;
  border: none;
  color: #000;
}

.show svg {
  width: 1rem;
  transition: transform 400ms ease-in-out;
}

.open .show svg {
  transform: rotate(-180deg);
}

.preview {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.top-three {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
}

.top-entry {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.top-entry .position {
  margin-right: 0.25rem;
}

.top-entry .count {
  margin-left: 0.25rem;
}

.top-entry.mine {
  font-size: 1rem;
  font-weight: 600;
}

.top-entry.mine .count {
  margin-left: 0.5rem;
}

.ellipsis {
  margin: 0 1rem;
}

main {
  max-height: 0.001vh;
  height: 100%;
  transition: max-height 400ms;
  overflow: hidden;
}

.leaderboard-key {
  padding-top: 1rem;
  text-align: center;
}

.mini {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.85rem;
  color: #6c757d;
}
.mini > a {
  color: #6c757d;
  font-weight: 600;
}

.open main {
  max-height: min(var(--max-height), 70vh);
}

.main-wrap {
  padding: 1rem;
  padding-top: 0;
  overflow-y: scroll;
  max-height: 70vh;
  justify-content: center;
}

</style>
