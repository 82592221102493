<template>
  <header>
    <section class="projects">
      <a href="https://maxwellcat.world/" class="project" @pointerdown.stop="" aria-label="Check out maxwellcat.world!">
        <img src="~@/assets/maxwell-cat-world.png" alt="">
      </a>
      <a href="https://shop.popcat.click/" class="project" @pointerdown.stop="" aria-label="Check out shop.popcat.click!">
        <img src="~@/assets/shop-popcat-click.png" alt="">
      </a>
    </section>
    <img class="title" src="~@/assets/popcat.svg" alt="POPCAT">
    <section class="projects">
      <a href="https://spinningrat.online/" class="project" @pointerdown.stop=""
        aria-label="Check out spinningrat.online!">
        <img src="~@/assets/spinning-rat-online.png" alt="">
      </a>
      <div class="project placeholder"></div>
    </section>
  </header>
</template>

<script>
export default {

  data() {
    // Months are 0 indexed so 4 = May
    let end_date = new Date(Date.UTC(2024, 4, 10, 19))
    let millis_in_day = 1000 * 60 * 60 * 24
    let millis_til_end = end_date - new Date()
    let days_until_end = Math.max(Math.ceil(millis_til_end / millis_in_day), 0)
    return {
      show_countdown: days_until_end <= 7,
      gone_today: millis_til_end < millis_in_day,
      days_until_end: days_until_end
    }
  }
}
</script>

<style scoped>
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100vw;
}

.title {
  width: min(300px, 50%);
  height: 4em;
  margin: 1em;
}

.projects {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  padding: 0.5em;
}

.project {
  max-width: 4em;
  max-height: 4em;
  width: 4em;
  height: 4em;
  margin: 0.5em;
  border-radius: 50%;
  aspect-ratio: 1;
}

.project>* {
  width: 100%;
  height: 100%;
}


@media(min-width:800px) {
  .title {
    width: min(300px, 80%);
    height: 5em;
  }

  .project {
    max-width: 5em;
    max-height: 5em;
    width: 5em;
    height: 5em;
  }

  .projects {
    flex-direction: row-reverse;
  }
}
</style>